<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="밀폐공간작업 훈련계획 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="traning.sopEmergencyTrainingId !='' && !disabled && editable" label="삭제" icon="delete_forever" @btnClicked="remove" />
                <c-btn
                  v-if="traning.sopEmergencyTrainingId !='' && !disabled && editable"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="traning"
                  mappingType="PUT"
                  label="완료"
                  icon="check"
                  @beforeAction="completePlan"
                  @btnCallback="completeCallback" 
                />
                <c-btn
                  v-if="!disabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="traning"
                  :mappingType="saveType"
                  label=""
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="훈련명"
                  name="trainingName"
                  v-model="traning.trainingName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :disabled="disabled"
                  :editable="editable"
                  label="훈련시작일시"
                  type="datetime"
                  :minuteStep="10"
                  name="trainingStartDate"
                  v-model="traning.trainingStartDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :disabled="disabled"
                  :editable="editable"
                  :start="traning.trainingStartDate"
                  label="훈련종료일시"
                  type="datetime"
                  :minuteStep="10"
                  name="educationDate"
                  v-model="traning.trainingEndDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  required
                  :editable="editable && param.sopEmergencyTrainingId ==''"
                  :disabled="true"
                  codeGroupCd="EAP_EVAL_CLASS_CD"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  name="eapTrainingTypeCd"
                  label="훈련구분"
                  v-model="traning.eapTrainingTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="훈련대상 밀폐공간"
                  name="trainingArea"
                  v-model="traning.trainingArea">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-tag 
                  :editable="editable"
                  :disabled="disabled"
                  itemText="userName"
                  itemValue="check"
                  label="교관"
                  name="trainingInstructUserIds" 
                  v-model="traning.trainingInstructUserIds"
                  icon="person"
                  @removeTag="removeInstructor"
                  @addTag="addInstructor"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant 
                  required
                  :disabled="disabled"
                  :editable="editable" 
                  type="edit" 
                  name="plantCd" 
                  v-model="traning.plantCd"
                  @datachange="plantChange" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="목적"
                  :rows="2"
                  name="checkPurpose"
                  v-model="traning.checkPurpose">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="훈련개요"
                  :rows="2"
                  name="summary"
                  v-model="traning.summary">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="훈련방법"
                  :rows="2"
                  name="trainingWay"
                  v-model="traning.trainingWay">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="훈련 시나리오 정보" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text 
                  :afterIcon="editable && !disabled ? [
                    { name: 'search', click: true, callbackName: 'searchTraningScenario', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeTraningScenario', color: 'red' },
                  ] : null"
                  :editable="editable"
                  :disabled="true"
                  label="훈련시나리오명"
                  name="trainingScenarioName"
                  v-model="traning.trainingScenarioName"
                  @searchTraningScenario="searchTraningScenario"
                  @removeTraningScenario="removeTraningScenario">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="Rev."
                  name="revisionNum"
                  v-model="traning.revisionNum">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="비상조치계획서명"
                  name="emergencyPlanName"
                  v-model="traning.emergencyPlanName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="5"
                  label="훈련내용요약"
                  name="trainingContentsSummary"
                  v-model="traning.trainingContentsSummary">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="5"
                  label="훈련시나리오개요"
                  name="trainingContentsOutline"
                  v-model="traning.trainingContentsOutline">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="5"
                  label="직접참가자 유의사항"
                  name="directlyNotice"
                  v-model="traning.directlyNotice">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="2"
                  label="간접참가자 유의사항"
                  name="indirectNotice"
                  v-model="traning.indirectNotice">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="2"
                  label="준비물"
                  name="materials"
                  v-model="traning.materials">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="2"
                  label="사전준비사항"
                  name="preRequisites"
                  v-model="traning.preRequisites">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="tableAttend"
            title="훈련참가자 목록"
            :columns="grid.columns"
            :data="traning.emergencyTrainingUsersModels"
            selection="multiple"
            :editable="editable && !disabled"
            :gridHeight="grid.height"
            :hideBottom="false"
            :isExcelDown="false"
            :usePaging="false"
            rowKey="attendUserId"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
                <c-btn v-if="editable && !disabled && traning.emergencyTrainingUsersModels.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="훈련 사진" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-12">
                <c-upload 
                  label="훈련 사진"
                  :attachInfo="attachInfo"
                  :editable="editable&&!disabled">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'traning-detail',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopEmergencyTrainingId: '',
        eapTrainingTypeCd: '',
        plantCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'TRAINING_PICTURE',
        taskKey: '',
      },
      grid: {
        columns: [],
        data: [],
        height: '360px'
      },
      traning: {
        sopEmergencyTrainingId: '',
        plantCd: '',
        trainingName: '',
        eapTrainingTypeCd: 'EVCC000010',
        checkPurpose: '',
        trainingStartDate: '',
        trainingStartTime: '',
        trainingEndDate: '',
        trainingEndTime: '',
        emergencyPlanName: '',
        summary: '',
        trainingWay: '',
        trainingArea: '',
        trainingInstructUserIds: [],
        sopEmergencyPlanId: '',
        trainingScenarioNo: '',
        evaluationUserId: '',
        evaluationOpinon: '',
        trainingCompleteFlag: 'N',
        regUserId: '',
        chgUserId: '',  

        emergencyTrainingUsersModels: [],
        emergencyTrainingEvaluationModels: [],

        // 훈련시나리오 
        trainingScenarioName: '',
        preRequisites: '',
        materials: '',
        indirectNotice: '',
        directlyNotice: '',
        trainingContentsOutline: '',
        trainingContentsSummary: '',
        revisionNum: '',

      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      saveCall: {
        saveCallData: '',
      },
      saveUrl: 'transactionConfig.sop.eap.training.plan.insert.url',
      saveType: 'POST',
      isSave: false,
      disabled: false,
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      isComplete: false,
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 400) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.sop.eap.training.plan.get.url;
      this.insertUrl = transactionConfig.sop.eap.training.plan.insert.url;
      this.updateUrl = transactionConfig.sop.eap.training.plan.update.url;
      this.deleteUrl = transactionConfig.sop.eap.training.plan.delete.url;
      this.getItemUrl = selectConfig.sop.eap.training.plan.getItem.url;
      this.completeUrl = transactionConfig.sop.eap.training.plan.update.url;
      // list setting
      this.getDetail();
      this.setHeader();
    },
    getDetail() {
      if (this.param.sopEmergencyTrainingId) {
        this.$http.url = this.$format(this.getUrl, this.param.sopEmergencyTrainingId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.traning = _result.data;
          if (this.traning.trainingCompleteFlag === 'Y') {
            this.disabled = true;
          }
          this.param.eapTrainingTypeCd = _result.data.eapTrainingTypeCd
          this.param.plantCd = _result.data.plantCd
          this.$set(this.attachInfo, 'taskKey', this.param.sopEmergencyTrainingId)
        },);
        this.saveCall.saveCallData = uid();
      }
    },
    plantChange() {
      /**
       * 교관 정보 reset
       */
      this.$set(this.traning, 'trainingInstructUserIds', [])
    },
    addInstructor() {
      this.popupOptions.title = '사용자 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (!this.traning.trainingInstructUserIds) this.traning.trainingInstructUserIds = [];
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.traning.trainingInstructUserIds, { userId: item.userId }) === -1) {
            this.traning.trainingInstructUserIds.push({
              userId: item.userId,
              userName: item.userName,
            })
          }
        })
      }
    },
    removeInstructor(item) {
      this.traning.trainingInstructUserIds = this.$_.reject(this.traning.trainingInstructUserIds, item)
    },
    searchTraningScenario() {
      this.popupOptions.title = '훈련 시나리오 검색'; // 관련 공정사고 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/eap/old/emergencyActionPlanDocuPop'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTraningScenarioPopup;
    },
    /* eslint-disable no-unused-vars */ 
    closeTraningScenarioPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.traning.emergencyPlanName = data[0].emergencyPlanName
        this.traning.trainingScenarioName = data[0].trainingScenarioName
        this.traning.trainingContentsSummary = data[0].trainingContentsSummary
        this.traning.preRequisites = data[0].preRequisites
        this.traning.revisionNum = data[0].revisionNum
        this.traning.directlyNotice = data[0].directlyNotice
        this.traning.indirectNotice = data[0].indirectNotice
        this.traning.trainingContentsOutline = data[0].trainingContentsOutline
        this.traning.materials = data[0].materials
        this.traning.sopEmergencyPlanId = data[0].sopEmergencyPlanId
        this.traning.trainingScenarioNo = data[0].trainingScenarioNo
      }
    },
    removeTraningScenario() {
      this.traning.trainingScenarioName = '';
      this.traning.trainingContentsSummary = '';
      this.traning.preRequisites = '';
      this.traning.revisionNum = '';
      this.traning.directlyNotice = '';
      this.traning.indirectNotice = '';
      this.traning.trainingContentsOutline = '';
      this.traning.materials = '';
      this.traning.sopEmergencyPlanId = '';
      this.traning.trainingScenarioNo = '';
    },
    savePlan() {
      if (this.param.sopEmergencyTrainingId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traning.regUserId = this.$store.getters.user.userId
              this.traning.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.sopEmergencyTrainingId = _result.data
      
      this.$set(this.attachInfo, 'taskKey', this.param.sopEmergencyTrainingId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$emit('insertTraningInfo', _result.data);
      this.getDetail();
    },
    completePlan() {
      if (this.param.sopEmergencyTrainingId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traning.regUserId = this.$store.getters.user.userId
              this.traning.chgUserId = this.$store.getters.user.userId

              this.traning.trainingCompleteFlag = 'Y';

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    datachange(data) {
      // value
      if (!this.traning.plantCd) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '사업장을 선택하세요.', 
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.getItemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopEmergencyTrainingId: this.param.sopEmergencyTrainingId ? this.param.sopEmergencyTrainingId : '',
        trainingTypeCd: data.value,
        plantCd: this.traning.plantCd,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.traning.emergencyTrainingEvaluationModels = _result.data;
        this.updateMode = true;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.traning.sopEmergencyTrainingId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setHeader() {
      this.$comm.getComboItems('EAP_TRAINING_USER_CLASS_CD', false).then(_result => {
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'attendUserName',
            field: 'attendUserName',
            label: '이름',
            align: 'center',
            style: 'width:100px',
            type: 'user',
            userId: 'attendUserId',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'eapAttendTypeCd',
            field: 'eapAttendTypeCd',
            label: '구분',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'select',
            comboItems: _result
          },
          {
            setHeader: true,
            name: 'completeFlag',
            field: 'completeFlag',
            label: '이수여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'nonattendanceRemark',
            field: 'nonattendanceRemark',
            label: '비고(불참사유)',
            align: 'left',
            sortable: false,
            type: 'text'
          },
        ]
      });
    },
    addItem() {
      this.popupOptions.title = "훈련참가자 검색"; // 훈련참가자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeItemPopup;
    },
    closeItemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.traning.emergencyTrainingUsersModels, {
            attendUserId: _item.userId,
          });
          if (index === -1) {
            this.traning.emergencyTrainingUsersModels.splice(0, 0, {
              sopEmergencyTrainingId: this.traning.sopEmergencyTrainingId,
              attendUserId: _item.userId,
              attendUserName: _item.userName,
              deptName: _item.deptName,
              eapAttendTypeCd: null,
              completeFlag: 'N',
              nonattendanceRemark: '',
            })
          }
        })
      }
    },
    removeItem() {
      let selectData = this.$refs['tableAttend'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.traning.emergencyTrainingUsersModels = this.$_.reject(this.traning.emergencyTrainingUsersModels, item)
        })
      }
    },
  }
};
</script>
